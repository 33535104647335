h1 {
    font-weight: 800;
}

h2 {
    font-weight: 800;
}

h3 {
    font-weight: 800;
}