.custom-modal {
    width: 40%;
    max-width: 500px;
  }
  
  .custom-modal__content-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .custom-modal .chakra-modal__close-btn.custom-modal__close-btn {
    top: 10px;
    right: 10px;
    background-color: #f91010;
    color: white;
    border-radius: 50%;
  }
  
  .custom-modal .chakra-modal__close-btn.custom-modal__close-btn:hover {
    background-color: #ea4a4a;
  }
  
  .custom-modal__image {
    border: #4299e1 5px solid;
    width: 25%;
    height: 25%;
    margin-top: -10%;
    align-self: center;
    border-radius: 50%;
  }
  
  .custom-modal__header {
    font-family: "Montserrat", sans-serif;
    font-size: 30px;
    font-weight: bold;
  }
  
  .custom-modal__text {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .custom-modal__button {
    font-size: 20px;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    width: 50%;
  }
  
  @media only screen and (max-width: 768px) {
    .custom-modal {
      width: 80% !important;
      max-width: 90% !important;
    }
    
    .custom-modal__image {
      width: 20%;
      height: auto;
      margin-top: -10%;
    }
  
    .custom-modal__header {
      font-size: 24px;
    }
  
    .custom-modal__text {
      font-size: 16px;
    }
  
    .custom-modal__button {
      font-size: 18px;
      padding: 8px 16px;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .custom-modal {
      width: 90% !important;
      max-width: 90% !important;
    }
  
    .custom-modal__image {
      width: 25%;
      height: auto;
      margin-top: -10%;
    }
  
    .custom-modal__header {
      font-size: 20px;
    }
  
    .custom-modal__text {
      font-size: 14px;
    }
  
    .custom-modal__button {
      font-size: 16px;
      padding: 6px 12px;
    }
  }
  