.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-prev,
.swiper-button-next {
  color: #424242;
}

/* new css  */
.mySwiper {
  width: 100%;
  height: auto;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-button-prev,
.swiper-button-next {
  color: #424242;
}

.trading-green-gradient {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px;
  border-radius: 10px 0 0 0;
  background: linear-gradient(
    90deg,
    rgba(14, 232, 61, 0.4) 0%,
    rgba(14, 232, 61, 0) 100%
  );
  font-size: 0.8rem;
  font-weight: 500;
  color: green;
}

.trading-orange-gradient {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px;
  border-radius: 10px 0 0 0;
  background: linear-gradient(
    90deg,
    rgba(232, 116, 14, 0.4) 0%,
    rgba(14, 232, 61, 0) 100%
  );
  font-size: 0.8rem;
  font-weight: 500;
  color: orange;
}

.h-30 {
  height: 30px;
}

.green {
  color: green;
}

.orange {
  color: orange;
}

.yes-btn,
.no-btn,
.bet-btn {
  width: 150px;
  min-height: 50px;
  font-size: auto;
  font-weight: 500;
  border-radius: 6px;
}

.yes-btn {
  background: linear-gradient(to top, #5aba75, #7ae097) !important;
}

.no-btn {
  background: linear-gradient(to top, #ff7f7f, #ffa8a8) !important;
}

.bet-btn {
  background-image: linear-gradient(to top, #295392, #7b9bd0) !important;
}

.card-container {
  height: 350px;
  max-width: 500px;
  border-radius: 10px;
  margin: 0 auto;
  position: relative;
  border: 1px solid #333333;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.card-container:hover {
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.2);
}

.question-text {
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  color: #1c4779;
  margin-top: 10px;
  padding: 5px 45px 5px 45px;
}

.hint-text {
  font-size: 0.7rem;
  font-weight: 500;
  text-align: center;
  color: #333333;
  padding: 5px 10px 5px 10px;
}

.tradingCard-img {
  width: auto;
  height: 130px;
  border-radius: 7px;
  margin-top: 10px;
}

.option-btn-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.option-btn {
  margin: 5px;
  width: 140px;
  min-height: 50px;
  font-size: auto;
  font-weight: 500;
  border-radius: 6px;
}

.option-btn-text {
  padding: 5px;
  text-align: center;
  white-space: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1025px) {
  .card-container {
    height: 300px;
    margin: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
  }
  .option-btn {
    width: 90px;
    min-height: 30px;
    align-items: center;
    justify-content: center;
  }
  .option-btn-text {
    padding: 5px;
    text-align: center;
    font-size: 8px;
  }
  .question-text {
    font-size: 14px;
    font-weight: 500;
    padding: 5px 45px 5px 45px;
  }

  .hint-text {
    font-size: 10px;
    font-weight: 300;
    padding: 5px 10px 5px 10px;
  }
}

/* Mobile screens (default) */
.banner-image {
  width: 100%;
  height: 200px;
}

/* Tablet screens */
@media (min-width: 768px) and (max-width: 1024px) {
  .banner-image {
    height: 500px;
  }
}

/* Desktop screens */
@media (min-width: 1025px) {
  .banner-image {
    height: 550px;
  }
}
