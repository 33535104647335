.flex-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1366px;
    margin: 0 auto;
    padding: 20px 0;
    flex-direction: column;
    background-color: #f4f4f4;
  }
  
  .box-container {
    flex: 2;
    width: 100%;
    padding: 20px;
  }
  
  .heading {
    font-family: 'Montserrat';
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  @media (min-width: 768px) {
   
  }
  
  @media (min-width: 1024px) {
    
  }
  
  .text-content {
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-align: justify;
    padding-left: 20px;
    padding-right: 20px;
    color: #696969;
  }
  
  @media (min-width: 768px) {
    .text-content {
      padding-left: 60px;
      padding-right: 60px;
    }

    .heading {
        font-size: 20px;
        font-weight: 500;
        padding-left: 60px;
        padding-right: 60px;
      }

      .heading {
        font-size: 25px;
        font-weight: 500;
      }
  }
  