
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

/* body {
  margin: 0;
  padding: 0;
  background-color: #fcfcfc;
} */

.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.logo {
  margin-left: -50px; 
  width: 10%;
  /* height: 150%; */
}
.navbar {
  height: 100px;
  /* background-color: #aec4ee; */
  width: "100%";
  position: relative;
}

/* .logo {
} */

.menu-icon {
  display: none;
}

/* .nav-elements {
} */

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li{
  font-size: 18px;
  line-height: 21.94px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
}
.nav-elements ul li:not(:last-child) {
  margin-right: 45px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 600;
  color: #2f234f;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #3379cb;
  font-weight: 600;
  position: relative;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
 
}

.btn-container button{
  width: 155px;
  height: 35px;
  border-radius: 2px;
  font-size: 14px;
  background-color: #327acc;
  border: 2px solid transparent;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  margin-top: -10px;
  margin-right: -50px;
  z-index: 1;
}
/* @media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
} */

@media (max-width: 900px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }
  .btn-container{
    margin-top: 25px;
  }
  .nav-elements {
    position: absolute;
    right: 0;
    top: 100px;
    background-color:white;
    width: 0px;
    height: calc(100vh - 60px);
    overflow: hidden;
    z-index: 100;
    /* padding: 2px; */
    /* left: 10px; */
  }
  .parent{
    padding: 10px;
  }
  .nav-elements.active {
    width: 270px;
    height: 468px;
    /* transition:  0.3s; */

  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
  .logo{
    margin-left: 30px;
  }
  .logo {
    width: 30%;
  }
 
}