@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.banner {
  display: block;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
}

.home-text-container {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  position: relative;
  /* z-index: -10; */
}

.home-icon1 {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
}

.home-icon2 {
  position: absolute;
  bottom: 0;
  right: 0;
  width: auto;
  height: auto;
}

.home-text-header {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: center;
  font-size: 35px;
  white-space: nowrap;
}

.home-text {
  position: absolute;
  top: 20%;
  padding: 100px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 1.25rem;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: justify;
  color: #696969;
  font-weight: 300;
}

.home-container3 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 2px solid black; */
}
.home-container3-head {
  font-family: "Montserrat", sans-serif;
  font-size: 2rem;
  text-align: center;
  color: #000000;
  padding-top: 2rem;
  font-weight: 600;
  
}
.home-container3-para {
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  text-align: justify;
  color: #000000;
  padding-top:1rem ;
  padding-left: 3rem;
  padding-right: 3rem;
}
.ring-container {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.ring {
  width: 60rem;
  height: auto;
  padding: 3rem;
  position: relative;
  /* border: 2px solid black; */
}

.ring img {
  width: 100%;
  height: auto;
  display: block;
}
.ring-head {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 20px;
  /* background-color: rgba(0, 0, 0, 0.5); */
  max-width: 80%;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  color: #fb6767;
}

.ring-para {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 10px;
  max-width: 80%;
  font-family: "Montserrat", sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  color: black;
}
.strip {
  width:10rem;
  height: 0.9rem;
  border-radius: 31px;
  align-items: center;
  position: absolute;
  top: 94%;
  left: 50%;
  transform: translate(-50%, -50%);
 
 
}

.redh {
    color: #fb6767;
  }
  .yellowh {
    color: #f4cd00;
  }
  .greenh {
    color: #34d257;
  }
.red {
  background: #fb6767;
}
.yellow {
  background: #f4cd00;
}
.green {
  background: #34d257;
}
.home-container4 {
  max-width: 100%;
  width: 1366px;
  /* height: 0; */
  padding-top: 55.95%;
  background: #2e74c6;
  margin: 0 auto;
  margin-top: -4px;
  position: relative;
}
.home-text-header4 {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: center;
  font-size: 35px;
  color: #ffffff;
}

.egg1 {
  position: absolute;
  top: -7px;
  left: 0;
  width: auto;
  height: auto;
}
.egg2 {
  position: absolute;
  top: -7px;
  right: 0;
  width: auto;
  height: auto;
}

/* .rev1{
    position: absolute;
    top: 28%;
    left: 2%;
} */

.review-container {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 30px;
  top: 27%;
  left: 3%;
}
.rev2 {
  margin-top: -10%;
}

.home-container5 {
  position: relative;
}
.mobile {
  position: absolute;
  top: 15%;
  right: 3%;
}
.mobile-container{
  width:100%;
}

.item-container5 {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 17%;
  left: 3%;
  gap: 3.5rem;
  width: 100%;
}
.item-header5 {
  font-family: "Montserrat", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: left;
}

.para-box {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.item-p-text {
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  font-weight: 300;
  text-align: left;
}

.item-img {
  display: flex;
  flex-direction: row;
  gap: 1.6rem;
}

.item-q-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.4rem;
}
.q-text {
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: left;
}

@media (max-width: 1200px) {
  .home-text-header {
    font-size: 2.5vw;
  }
  .home-text {
    font-size: 2vw;
    line-height: 2vw;
    padding: 3vw;
  }
  .home-text-header2 {
    font-size: 2.5vw;
  }
  .home-text2 {
    font-size: 2vw;
    line-height: 2vw;
    padding: 3vw;
    top: 15vw;
  }
  .item-container {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    top: 38%;
  }
  /* .strip {
    width: 50%;
    top: 1.2vw;
    height: 1.3vw;
  } */

  .strip {
    width: 10rem;
    height: 1rem;
    border-radius: 31px;
    align-items: center;
    position: absolute;
    top: 92%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .home-text2 {
    font-size: 2vw;
    line-height: 2vw;
    padding: 2vw;
    top: 10vw;
  }

  .home-text-header4 {
    font-size: 2.5vw;
    top: 16%;
  }
}

@media (max-width: 767px) {
  /* .review-container{
      overflow: auto;
      max-width: 300px;
    } */

   .ring-container{
    flex-direction: column;
    align-items: center;
   } 
   .ring{
    width: 97%;
   }
  .review-container {
    /* gap: 30px; */
    top: 29%;
    left: -4%;
  }

  .ring-head {
    /* padding: 20px; */
    max-width: 80%;
    font-size: 0.8rem;
    font-weight: 500;
    
  }
  .ring-para{
    /* padding: 20px; */
    max-width: 80%;
    font-size: 0.7rem;
    font-weight: 400;
  }

  .home-text-header4{
    top: 5%;
  }
  .egg1 {
    width: 50%;
  }
  .egg2 {
    width: 50%;
  }
  .rev1,
  .rev2,
  .rev3 {
    width: 26%;
    margin-right: 1rem;
  }
  .item-header5 {
    width: 0.8;
  }
  .item-p-text {
    font-family: "Montserrat", sans-serif;
    font-size: 0.5rem;
    font-weight: 300;
    text-align: left;
  }
  .item-container5 {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 5%;
    left: 3%;
    gap: 0.5rem;
    width: 97%;
  }
  .g-img,
  .a-img {
    width: 20%;
  }
  .q-img {
    width: 20%;
  }

  .item-q-container {
    width: 60%;
    /* margin-top: 0.5rem; */
  }
  .q-text {
    font-size: 0.6rem;
  }
  .mobile {
    width: 40%;
    top: 20%;
    right: 3%;
  }
}

@media (max-width: 900px) {
  .home-icon1 {
    top: 1%;
    left: 1%;
    max-width: 30%;
  }

  .home-icon2 {
    bottom: 1%;
    right: 1%;
    max-width: 30%;
  }
  .hex {
    width: 30%;
    border: 20px soldi black;
  }
  .item-container {
    gap: 10px;
  }
}
